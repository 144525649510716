const resetPasswordPage = {
  key: 'resetPasswordPage',
  name: 'Reset Password Page',
  siteIdentifier: 'petsafe',

  title: 'Reset Password | PetSafe',
  reactivateAccountHeader: 'Reactivate Account',
  resetPasswordHeader: 'Reset Password',
};

exports.resetPasswordPage = resetPasswordPage;
