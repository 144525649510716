const contactUsPage = {
  key: 'contactUsPage',
  name: 'Support Contact Us Page',
  siteIdentifier: 'petsafe',

  title: 'Contact Us | Customer Care | Product Support | PetSafe',
  description:
    'Contact our customer care and support at 1-866-738-4379 or use our online guide for product support and manuals.',
  contactUsHeader: 'Contact Us',
};

exports.contactUsPage = contactUsPage;
