const waitForElement = (selector: any) =>
  new Promise(resolve => {
    if (document.querySelector(selector)) {
      resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        observer.disconnect();
        resolve(document.querySelector(selector));
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });

export const addRefreshButtonOnWebPreview = () => {
  waitForElement('gatsby-fast-refresh').then(() => {
    const siteMainDiv = document.getElementById('___gatsby');

    // Check if gatsby error occurs but page is rendered
    if (siteMainDiv.innerHTML !== '') return;

    const btn = document.createElement('button');
    btn.innerText = 'refresh page';
    btn.style.border = '1px solid black';
    btn.style.borderRadius = '5px';
    btn.style.margin = '5px';
    btn.style.padding = '5px';
    btn.onclick = () => {
      window.location.reload();
    };

    const label = document.createElement('label');
    label.innerText = 'Something wrong with Web Preview. Try to';

    document.body.appendChild(label);
    document.body.appendChild(btn);
  });
};
