const glossaryPage = {
  key: 'glossaryPage',
  name: 'Support - glossary',
  siteIdentifier: 'petsafe',

  title: 'Glossary',

  // Navigation items
  aggressiveBehavior: 'Aggressive Behavior',
  aggressiveBehaviorDescription: `Never use electronic collars to correct or eliminate any form
of aggressive behavior. This includes Remote Trainers, Bark
Control Collars, and Fence Receiver Collars. Examples of
aggressive behavior/body language in dogs could include: ears
back, body low to the ground, snarling, growling, fleeing,
lunging, nipping, biting, etc. Contact your local veterinarian
or professional trainer to determine if your pet might be
aggressive.`,
  zone: 'Boundary Zone/Static Correction Zone',
  zoneDescription: `The Boundary Zone is the area you want your pet to stay away
from. Once your pet passes from the safe designated Pet Area
into the Warning Zone, he will hear a warning beep that he’s
getting close to the Boundary Zone. If he enters the Boundary
Zone, your pet’s Receiver Collar will begin to deliver a
Static Correction, signaling him to return to the Pet Area.
The correction will continue until he returns to the Pet Area.`,
  door: 'Building Up a Door',
  doorDescription: `If you have a pet door and want to replace it with a smaller
pet door, you can build up the hole by using shims, small
pieces of wood used to adjust space between two objects. Shims
are sold at hardware or home improvement stores.`,
  panel: 'Closing Panel',
  panelDescription: `A closing panel is a cover for your PetSafe door that snaps on
or slides in, usually on the interior side, to prevent your
pet from using the door. This is useful during inclement
weather or when you need to limit your pet’s access.`,
  points: 'Contact Points',
  pointsDescription: `Two metal pieces on the receiver collar that deliver the safe
Static Correction to your pet. They come in two sizes, short
and long. Long contact points may be better for pets with
longer hair.`,
  correction: 'Correction/Level of Correction',
  correctionDescription: `The level of correction your pet will receive. PetSafe offers
4 kinds of correction: spray, static, vibration, and
ultrasonic. Each correction type is safe, mild, and used as a
training cue to re-focus your pet’s attention and interrupt
his behavior. The correct level for your pet causes a small,
curious reaction, such as the ears rising or the head tilting.`,
  cutOutSize: 'Cut-Out Size',
  cutOutSizeDescription: `The opening you cut in your door for pet door installation.`,
  doorFlap: 'Door Flap',
  doorFlapDescription: `One, two, or three pieces that go in between the frames of
your door. This is the part your pet passes through to come in
and out. The door may have one rectangular/square flap or one
flap and one U-shaped flap. The Extreme Weather Door features
a three flap system.`,
  doorFrame: 'Door Frame',
  doorFrameDescription: `The pet door frame is made of two parts: the interior part and
the exterior part. The internal frame is the frame for the pet
door on the inside, and the external frame is the part on the
outside of the home. These two pieces fit together to hold a
flap in between the two frames.`,
  doorRise: 'Door Rise',
  doorRiseDescription: `The height from the floor to the bottom of the flap in the pet
door; it is the height the pet must step over to enter or exit
the pet door.`,
  flapSize: 'Flap Size',
  flapSizeDescription: `The size of the entire flap itself. This is not the size of
the hole your pet enters, this is the measurement of the
entire flap.`,
  flapOpeningSize: 'Flap Opening Size',
  flapOpeningSizeDescription: `The opening in the pet door; the usable flap space for your
pet to enter and exit through the pet door. This is not the
overall size of the flap itself.`,
  frameSize: 'Frame Size',
  frameSizeDescription: `Overall pet door dimensions, usually measured on the outer
edge of the interior frame.`,
  glassSweep: 'Glass Sweep',
  glassSweepDescription: `A thick rubber sealing strip used in the patio doors to adhere
to the pet panel and seal it to the stationary panels, giving
you a better fit and less drafts.`,
  perfectBarkDetection: 'Perfect Bark Detection',
  perfectBarkDetectionDescription: `A PetSafe technology that combines multiple bark detection
methods to ensure that your dog&apos;s bark is the only sound
that will cause a correction. This technology uses both
vibration and sound sensors, so the collar will only activate
when it detects both your dog’s vocal chords moving and the
sound of your dog’s bark. This is critical to ensuring barking
is controlled effectively and that your pet is properly
trained to stop barking without unnecessary corrections.`,
  petArea: 'Pet Area',
  petAreaDescription: `The area in which your pet can roam freely. This is the area
you train your pet to stay within safely without receiving
correction.`,
  petProofing: 'Pet Proofing',
  petProofingDescription: `Changing your indoor or outdoor living environment to make it
safe for pets or to prevent pets from getting to certain
areas. This may include securing trash cans, blocking pets
from scratching or climbing on furniture, making certain rooms
or areas off-limits, etc. Our pet proofing options are safe,
effective, and portable.`,
  petSize: 'Pet Size',
  petSizeDescription: `Your pet’s height is measured from the tallest part of the
pet, the top of the shoulders to the bottom of the chest or
belly. Width is measured from the widest part of the pet, the
chest or hips.`,
  pressureNecrosis: 'Pressure Necrosis',
  pressureNecrosisDescription: `If a collar is left on a pet for too long or put on too tight,
it can cause inflammation and edema. It’s possible for this to
be mistaken as a burn of some type. This condition can
deteriorate into severe edema and loss of blood flow. The
result is what is known as pressure necrosis.`,
  progressiveStaticCorrection: 'Progressive Static Correction',
  progressiveStaticCorrectionDescription: `With Progressive Static Correction, static correction begins
at the lowest level and automatically increases to the maximum
level within 3 seconds.`,
  quickFitBuckle: 'QuickFit Buckle',
  quickFitBuckleDescription: `A PetSafe feature on select collars that lets you quickly and
easily take the collar on and off and provides perfect collar
fit every time.`,
  radioFrequency: 'Radio-Frequency Interference',
  radioFrequencyDescription: `Radio-frequency interference or “noise” is due to
radio-frequency signals from other household appliances or
electronic products that disrupt the receiver from receiving a
signal from the transmitter. Interference can be minimal,
constant, or ever changing based on usage and closeness of
other electronic household items during operation. Household
appliances and common electronic products should be placed at
least two feet (60 cm) away from the transmitter.`,
  receiverCollar: 'Receiver Collar',
  receiverCollarDescription: `Receives the radio signal from the transmitter to deliver
correction.`,
  runThroughPrevention: 'Run-Through Prevention',
  runThroughPreventionDescription: `A technology that is designed to ensure your pet&apos;s safety
by automatically increasing the static correction level if
your dog runs or darts toward the Boundary Zone to keep him
from running through it.`,
  staticCorrection: 'Static Correction/Static Stimulation',
  staticCorrectionDescription: `This is a harmless, mild electrical stimulation used as a
training cue to re-focus your pet’s attention and interrupt
their behavior. The correct level for your pet causes a small,
curious reaction, such as the ears rising or the head tilting.
Most pets quickly learn to avoid receiving a correction by
only displaying acceptable behavior.

Imagine a slight tickle. Or a light static-like surprise
touching a metal doorknob. “Static correction” products are
actually quite mild, even when set at the highest levels (and
most pets learn easily at the lower levels). They work so well
because they surprise your dog or cat, not because they cause
pain.

How do static collars work? Static collars contain two small,
safe contact points that gently administer a mild electrical
stimulation through the skin. Pets experience a tingling
sensation that startles them and interrupts their behavior.
When used according to the training guide, teaching your pet
the right behavior is quick and easy. PetSafe also offers
static mats that can be placed in “off-limit” areas that
deliver stimulation through the paws when stepped on.`,
  surgeProtector: 'Surge Protector',
  surgeProtectorDescription: `A feature on certain fencing models, included with the Fence
Transmitter to protect the system from lightning strikes and
power surges (USA and Canada only).`,
  timeOut: 'Time-Out',
  timeOutDescription: `A function on certain receiver collars where the collar will
stop delivering correction after a set period of time, usually
15 or 30 seconds.`,
  toneOnlyMode: 'Tone Only Mode',
  toneOnlyModeDescription: `Sends a warning beep through your pet’s collar. There is no
stimulation associated with this button.

With consistent training, this button can serve as either a
Negative or Positive Tone. Timing is critical. You can use the
Positive Tone as a marker to reinforce positive behavior by
praising or treating the dog immediately following the
Positive Tone. Or, you can use a Negative Tone with correction
to stop unwanted behaviors by following the tone with the
correction during or immediately after the unwanted behavior.
Once your dog associates the Positive Tone with praise or the
Negative Tone with the correction, you will only need to use
the Tone.`,
  transmitter: 'Transmitter',
  transmitterDescription: 'Transmits the radio signal to the receiver collar.',
  warningZone: 'Warning Zone',
  warningZoneDescription: `The area between the Pet Area and the Boundary Zone. If your
pet leaves the Pet Area and enters the Warning Zone, he will
hear a warning beep from his receiver collar. If he progresses
past the Warning Zone into the Boundary Zone, he will receive
a static correction as a signal to return to his established
area.`,

// Bode text
  quickGuide: 'Quick guide for proper care:',
  avoidLeaving: `Always avoid leaving an electronic collar on a pet for
more than 12 hours per day.`,
  reposition: `When possible, reposition the collar on the pet’s neck
every 1 to 2 hours.`,
  leashElectronicCollar: `Never connect a leash to the electronic collar; it will
cause excessive pressure on the contacts.`,
  separateCollar: `When using a separate collar for a leash, don’t allow it
to put pressure on the electronic collar.`,
  neckArea: `Wash the pet’s neck area and the contacts of the collar
weekly with a damp cloth.`,
  examineContactArea: `Examine the contact area daily for signs of a rash or a
sore. Examine the contact area daily for signs of a rash
or a sore.`,
  discontinueUse: `If a rash or sore is found, discontinue use of the
collar until the skin has healed. If the condition
persists beyond 48 hours, see your veterinarian.`,
  properFit: 'To assure a proper fit, please follow these steps:',
  electronicCollarTurnedOff: `Start with the electronic collar turned off and your dog
standing comfortably, not sitting.`,
  placeTrainingCollar: `Place the training collar on your dog’s neck close to
the ears. Center the contact points underneath your
dog’s neck, touching the skin.`,
  note: 'NOTE:',
  necessaryToTrim: `It is sometimes necessary to trim
the hair around the contact points to make sure that
contact is consistent. Do not shave the pet’s neck as
this may increase the risk of skin irritation.`,
  tightness: `Check the tightness of the training collar by inserting
one finger between the collar and your dog’s neck. The
fit should be snug but not constricting.`,
  trainingCollar: `Allow your dog to wear the training collar for several
minutes then recheck the fit. Check the fit again as
your dog becomes more comfortable with the training
collar.`,
  remember: 'Remember',
  burnsPetSkin: 'Electronic collars cannot cause burns to a pet’s skin.',
  professionals: `Training professionals can be hired to help you fit and
train your pet to the electronic collar. This will help
you avoid any injuries to your pet’s skin.`,
  avoidLeavingCollar: `Avoid leaving an electronic collar on your pet for
extended periods of time. Follow the manufacturer
recommendations for wear and use.`,
  cleanYourPet: `Clean your pet’s neck and the electronic collar
(especially contact points) regularly.`,
};

exports.glossaryPage = glossaryPage;
