const subscriptionPaymentPage = {
  key: 'subscriptionPaymentPage',
  name: 'Subscription Payment',
  paymentMethodsHeader: 'Payment Methods',
  logoutLink: 'Logout',
  paymentAlertLabel: `Your payment methods could not be loaded at this time. Please try
again later.`,
};

exports.subscriptionPaymentPage = subscriptionPaymentPage;
