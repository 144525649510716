const cartPage = {
  key: 'cartPage',
  name: 'Cart Page',
  siteIdentifier: 'petsafe',

  title: 'PetSafe Shopping Cart',
  freeShippingTitle: 'This order qualifies for FREE SHIPPING!',
  // eslint-disable-next-line no-template-curly-in-string
  freeShippingOnOrdersTitle: 'Free Shipping on orders over {shippingInfo}!',
  checkoutButton: 'Checkout | {amount}',
  totalsSubtotal: 'Subtotal',
  totalsDiscount: 'Discount',
  totalsDiscountGrandTotal: 'Grand Total',
  noItemsMessage: 'You have no items in your cart.',
  continueShoppingLink: 'Continue Shopping',
};

exports.cartPage = cartPage;
