const verifyAccountPage = {
  key: 'verifyAccountPage',
  name: 'Verify Account Page',
  siteIdentifier: 'petsafe',

  title: 'Verify Account | PetSafe',

  successMessage:
    'You have successfully requested a new verification link. Please check your inbox.',
  failMessage:
    'There was a problem requesting a new verification code. Please try again later or contact customer support.',
  invalidRequestMessage: 'Invalid request.',
  accountVerificationHeader: 'Account Verification',
};

exports.verifyAccountPage = verifyAccountPage;
