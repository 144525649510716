const logoutPage = {
  key: 'logoutPage',
  name: 'Logout Page',
  siteIdentifier: 'petsafe',

  logoutTitle: 'Logout | PetSafe',
  logoutSubtitle: 'See You Again Soon!',
};

exports.logoutPage = logoutPage;
