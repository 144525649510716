const subscriptionPaymentMethodPanel = {
  key: 'subscriptionPaymentMethodPanel',
  name: 'Subscription Payment Method Panel',
  associatedSubscriptionsLabel: 'Associated Subscriptions',
  updatingPaymentMethodAlertMessage: `Updating this payment method will update 
{filteredSubscriptionsCount} associated subscriptions:`,
  autoRenewLabel: 'Auto-renews every {range}',
  alertDescriptionTLabel:
    'Any updates to this payment method must be completed in checkout.',
  sendUpdateLinkLabel: 'Send Update Link',
  toReceiveEmailLabel: `to receive an email with a link to update this payment method. Please check your spam folder if you don't receive the email within 10 minutes.`,
  backButton: 'Back',
  editButton: 'Edit',
  successToastLabel: 'Update link sent.',
  errorToastLabel:
    'Update link could not be sent at this time. Please try again later.',
  sendUpdateLinkButton: 'Send Update Link',
};

exports.subscriptionPaymentMethodPanel = subscriptionPaymentMethodPanel;
