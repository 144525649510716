const notFoundPage = {
  key: 'notFoundPage',
  name: '404 Not Fount Page',
  siteIdentifier: 'petsafe',

  title: "We're sorry, the page you're looking for cannot be found - PetSafe",
  pageNotFoundMessage:
    'The page you have requested does not exist or is no longer available.',
  verifyWebAddressMessage:
    'If you feel you have reached this page by mistake, please verify the web address and try again.',
  getYouBackMessage: 'Let’s get you back on track...',
  sorry: "We're sorry...",
  shopButton: 'Shop',
  supportButton: 'Support',
  blogButton: 'Blog',
};

exports.notFoundPage = notFoundPage;
