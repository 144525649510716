const registerPage = {
  key: 'registerPage',
  name: 'Register Page',
  siteIdentifier: 'petsafe',

  title: 'Create Account | PetSafe',
  accountDescription: `Your account can be used to sign in with any of our family of
brands.`,
  oneAccountModalHeader: 'One Account for Everything your Pet Needs',
  familyOfBrandsModalTitle:
    'Your account can also be used across our family of brands',
  ifbButton: 'Invisible Fence Brand',
  ifbSiteAriaLabel: 'Invisible Fence Brand Website',
  kurgoButton: 'Kurgo',
  kurgoSiteAriaLabel: 'Kurgo Website',
  petSafeButton: 'PetSafe',
  petSafeSiteAriaLabel: 'PetSafe Website',
  sportDOGButton: 'SportDOG',
  sportDOGSiteAriaLabel: 'SportDOG Website',
  createAccountLabel: 'Create Account',
  learnMoreButton: 'Learn more',
};

exports.registerPage = registerPage;
