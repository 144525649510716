const emailUpdatesPage = {
  key: 'emailUpdatesPage',
  name: 'Email updates Page',
  siteIdentifier: 'petsafe',

  title: 'Sign up for Newsletter - PetSafe',
  description:
    'Sign up today for the latest news, promotions, and more from PetSafe® brand.',
  bodyText: `Sign up for exclusive training tips, product information,
promotions, and information about conservation efforts. We won’t
overwhelm you with emails, and we promise not to send you anything
we wouldn’t want in our inbox.`,
  interestListHeader: 'I am interested in the following',
  cancelAndReturnToPreviousPageLink: 'Cancel and return to previous page',
  emailLabel: 'Email',
  submitButton: 'Submit',
  catOption: 'Cat',
  dogOption: 'Dog',
  emailValidationMessage: 'Please re-check your email address.',
  emailRequiredValidationMessage: 'Email address required',
  formSubmissionFailedMessage:
    'Form submission failed. Please try again later.',
  selectInterestMessage: 'Please select an interest',
};

exports.emailUpdatesPage = emailUpdatesPage;
