const { GLOBAL_LOCALES } = require('@rsc/gatsby-localization-helpers');

const regions = {
  Americas: 'Americas',
  Europe: 'Europe',
  Oceania: 'Oceania',
};

const defaultLanguages = [
  {
    ...GLOBAL_LOCALES.English,
    countryName: 'United States',
    region: regions.Americas,
    isDefault: true,
  },
];

const allLanguages = [
  {
    ...GLOBAL_LOCALES.English,
    countryName: 'United States',
    region: regions.Americas,
    isDefault: true,
  },
  {
    ...GLOBAL_LOCALES.Australia,
    countryName: 'Australia',
    region: regions.Oceania,
  },
  {
    ...GLOBAL_LOCALES.EnglishUK,
    countryName: 'United Kingdom',
    region: regions.Europe,
  },
  {
    ...GLOBAL_LOCALES.FrenchBelgium,
    countryName: 'Belgium (Belgique)',
    region: regions.Europe,
  },
  {
    ...GLOBAL_LOCALES.French,
    countryName: 'France (Français)',
    region: regions.Europe,
  },
  {
    ...GLOBAL_LOCALES.Germany,
    countryName: 'Germany (Deutschland)',
    region: regions.Europe,
  },
  {
    ...GLOBAL_LOCALES.EnglishIreland,
    countryName: 'Ireland',
    region: regions.Europe,
  },
  {
    ...GLOBAL_LOCALES.Italian,
    countryName: 'Italy (Italia)',
    region: regions.Europe,
  },
  {
    ...GLOBAL_LOCALES.EnglishNetherlands,
    countryName: 'Netherlands',
    region: regions.Europe,
  },
  {
    ...GLOBAL_LOCALES.Spanish,
    countryName: 'Spain (España)',
    region: regions.Europe,
  },
  {
    ...GLOBAL_LOCALES.GermanSwitzerland,
    countryName: 'Switzerland (Schweiz)',
    region: regions.Europe,
  },
];

const languages =
  process.env.GATSBY_ENABLE_ALL_LOCALES === 'true'
    ? allLanguages
    : defaultLanguages;

const defaultLanguage = languages.find(lg => lg.isDefault)?.id || '';

module.exports = {
  defaultLanguage,
  languages,
};
