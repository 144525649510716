import RootWrapper from './RootWrapper';
import { PageWrapper } from './PageWrapper';
import { addRefreshButtonOnWebPreview } from './scripts/addRefreshButtonOnWebPreview';

import 'react-image-gallery/styles/css/image-gallery.css';
import 'lite-youtube-embed/src/lite-yt-embed.css';
import './src/styles/globals.css';

export const onClientEntry = () => {
  if (
    typeof window === 'undefined' ||
    process.env.GATSBY_SANITY_PREVIEW_DEPLOY !== 'true'
  )
    return;

  // Show refresh button on Web Preview if it crashes with gatsby develop error
  addRefreshButtonOnWebPreview();
};

export const wrapRootElement = RootWrapper;

export const wrapPageElement = PageWrapper;
