const subscriptionsPage = {
  key: 'subscriptionsPage',
  name: 'Subscription',
  pageTitleLabel: 'Account - Subscriptions | PetSafe',
  homeBreadcrumbLabel: 'Home',
  accountBreadcrumbLabel: 'Account',
  subscriptionsBreadcrumbLabel: 'Subscriptions',
  detailsMethodsBreadcrumbLabel: 'Details',
  paymentMethodsLabel: 'Payment Methods',
  logoutLabel: 'Logout',
  paymentAlertLabel: `Your payment methods could not be loaded at this time. Please try
again later.`,
  pleaseLabel: 'Please',
  updateYourCreditLabel: 'update your credit card information',
  interruptedLabel: `to ensure your subscriptions aren't interrupted.`,
  activeSubscriptionsLabel: 'Active Subscriptions',
  errorSubscriptionsMessageLabel: `Your subscriptions could not be loaded at this time. Please try
again later.`,
  subscriptionManagementLabel: `Subscription management has moved to our portal. Click the
button below to manage your subscriptions.`,
  openSubscriptionManagementLabel: 'Open Subscription Management Portal',
  noSubscriptionMessageLabel: `It looks like you're not subscribed to any products at the
moment. Let's fix that.`,
  shopAllProductsLabel: 'Click here to shop all products',
  needHelpLabel: `Still need help? Don't see what you're looking for?`,
  contactCustomerCareLabel: 'Please contact Customer Care at {phone}.',
  openHoursLabel: `We are open Monday through Friday 8 am - 8 pm ET and Saturday 9
am - 5 pm ET.`,
};

exports.subscriptionsPage = subscriptionsPage;
