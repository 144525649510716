const accountSettingsPage = {
  key: 'accountSettingsPage',
  name: 'Account Settings Page',
  siteIdentifier: 'petsafe',

  title: 'Account - Settings | PetSafe',

  successMessageTitle: 'Account Updated',
  successMessageDescription: "You're all set",

  errorMessageTitle: 'Something went wrong',
  errorMessageDescription:
    'Please refresh the page and try again. If the error persists, please reach out to our customer care team for further assistance.',
};

exports.accountSettingsPage = accountSettingsPage;
