const loginPage = {
  key: 'loginPage',
  name: 'Login Page',
  siteIdentifier: 'petsafe',

  title: 'Login | PetSafe',
  returningCustomerQuestion: 'Returning Customer?',
  accountInfoMovedToNewStoreMessage: `We have safely moved your account information to our new store,
but you’ll need to reactivate it by creating a new password.`,
  reactivatePetsafeAccountLink: 'Reactivate my PetSafe account',
  checkoutFaster: 'Checkout faster',
  manageSubscriptions: 'Manage and update subscriptions',
  shippingAddresses: 'Save multiple shipping addresses',
  trackNewOrders: 'Track new orders',
  accessOrderHistory: 'Access your order history',
  createAccountButton: 'Create Account',
  signInLabel: 'Sign In',
};

exports.loginPage = loginPage;
