const emailUpdatesSuccessPage = {
  key: 'emailUpdatesSuccessPage',
  name: 'Email Updates Success Page',
  siteIdentifier: 'petsafe',

  title: 'Sign up for Newsletter - Success - PetSafe',
  description:
    'Get Email Updates. Sign up for exclusive training tips, product information, promotions, and information about conservation efforts.',
  addEmailSuccessMessage: `Thank you for signing up for our mailing list! Expect your first email
shortly.`,
  continueShoppingButton: 'Continue Shopping',
  successHeader: 'Success',
};

exports.emailUpdatesSuccessPage = emailUpdatesSuccessPage;
