const { GLOBAL_LOCALES } = require('@rsc/gatsby-localization-helpers');

const localizedShopifySettings = {
  [GLOBAL_LOCALES.English.id]: {
    accessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
    shopName: process.env.GATSBY_SHOPIFY_SHOP_NAME,
    checkoutBaseUrl: process.env.GATSBY_CHECKOUT_BASE_URL,
    shopifyAppPassword: process.env.SHOPIFY_APP_PASSWORD,
    storefrontAccessToken: process.env.GATSBY_SHOPIFY_CUSTOMER_STOREFRONT_TOKEN,
  },
  [GLOBAL_LOCALES.Australia.id]: {
    accessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN_AU,
    shopName: process.env.GATSBY_SHOPIFY_SHOP_NAME_AU,
    checkoutBaseUrl: process.env.GATSBY_CHECKOUT_BASE_URL_AU,
    shopifyAppPassword: process.env.SHOPIFY_APP_PASSWORD_AU,
    storefrontAccessToken:
      process.env.GATSBY_SHOPIFY_CUSTOMER_STOREFRONT_TOKEN_AU,
  },
  [GLOBAL_LOCALES.FrenchBelgium.id]: {
    accessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN_BE,
    shopName: process.env.GATSBY_SHOPIFY_SHOP_NAME_BE,
    checkoutBaseUrl: process.env.GATSBY_CHECKOUT_BASE_URL_BE,
    shopifyAppPassword: process.env.SHOPIFY_APP_PASSWORD_BE,
    storefrontAccessToken:
      process.env.GATSBY_SHOPIFY_CUSTOMER_STOREFRONT_TOKEN_BE,
  },
  [GLOBAL_LOCALES.French.id]: {
    accessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN_FR,
    shopName: process.env.GATSBY_SHOPIFY_SHOP_NAME_FR,
    checkoutBaseUrl: process.env.GATSBY_CHECKOUT_BASE_URL_FR,
    shopifyAppPassword: process.env.SHOPIFY_APP_PASSWORD_FR,
    storefrontAccessToken:
      process.env.GATSBY_SHOPIFY_CUSTOMER_STOREFRONT_TOKEN_FR,
  },
  [GLOBAL_LOCALES.Germany.id]: {
    accessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN_DE,
    shopName: process.env.GATSBY_SHOPIFY_SHOP_NAME_DE,
    checkoutBaseUrl: process.env.GATSBY_CHECKOUT_BASE_URL_DE,
    shopifyAppPassword: process.env.SHOPIFY_APP_PASSWORD_DE,
    storefrontAccessToken:
      process.env.GATSBY_SHOPIFY_CUSTOMER_STOREFRONT_TOKEN_DE,
  },
  [GLOBAL_LOCALES.EnglishIreland.id]: {
    accessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN_IE,
    shopName: process.env.GATSBY_SHOPIFY_SHOP_NAME_IE,
    checkoutBaseUrl: process.env.GATSBY_CHECKOUT_BASE_URL_IE,
    shopifyAppPassword: process.env.SHOPIFY_APP_PASSWORD_IE,
    storefrontAccessToken:
      process.env.GATSBY_SHOPIFY_CUSTOMER_STOREFRONT_TOKEN_IE,
  },
  [GLOBAL_LOCALES.Italian.id]: {
    accessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN_IT,
    shopName: process.env.GATSBY_SHOPIFY_SHOP_NAME_IT,
    checkoutBaseUrl: process.env.GATSBY_CHECKOUT_BASE_URL_IT,
    shopifyAppPassword: process.env.SHOPIFY_APP_PASSWORD_IT,
    storefrontAccessToken:
      process.env.GATSBY_SHOPIFY_CUSTOMER_STOREFRONT_TOKEN_IT,
  },
  [GLOBAL_LOCALES.EnglishNetherlands.id]: {
    accessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN_NL,
    shopName: process.env.GATSBY_SHOPIFY_SHOP_NAME_NL,
    checkoutBaseUrl: process.env.GATSBY_CHECKOUT_BASE_URL_NL,
    shopifyAppPassword: process.env.SHOPIFY_APP_PASSWORD_NL,
    storefrontAccessToken:
      process.env.GATSBY_SHOPIFY_CUSTOMER_STOREFRONT_TOKEN_NL,
  },
  [GLOBAL_LOCALES.Spanish.id]: {
    accessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN_ES,
    shopName: process.env.GATSBY_SHOPIFY_SHOP_NAME_ES,
    checkoutBaseUrl: process.env.GATSBY_CHECKOUT_BASE_URL_ES,
    shopifyAppPassword: process.env.SHOPIFY_APP_PASSWORD_ES,
    storefrontAccessToken:
      process.env.GATSBY_SHOPIFY_CUSTOMER_STOREFRONT_TOKEN_ES,
  },
  [GLOBAL_LOCALES.GermanSwitzerland.id]: {
    accessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN_CH,
    shopName: process.env.GATSBY_SHOPIFY_SHOP_NAME_CH,
    checkoutBaseUrl: process.env.GATSBY_CHECKOUT_BASE_URL_CH,
    shopifyAppPassword: process.env.SHOPIFY_APP_PASSWORD_CH,
    storefrontAccessToken:
      process.env.GATSBY_SHOPIFY_CUSTOMER_STOREFRONT_TOKEN_CH,
  },
  [GLOBAL_LOCALES.EnglishUK.id]: {
    accessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN_GB,
    shopName: process.env.GATSBY_SHOPIFY_SHOP_NAME_GB,
    checkoutBaseUrl: process.env.GATSBY_CHECKOUT_BASE_URL_GB,
    shopifyAppPassword: process.env.SHOPIFY_APP_PASSWORD_GB,
    storefrontAccessToken:
      process.env.GATSBY_SHOPIFY_CUSTOMER_STOREFRONT_TOKEN_GB,
  },
};

module.exports = {
  localizedShopifySettings,
};
