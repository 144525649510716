const breadcrumbsCommon = {
  key: 'breadcrumbsCommon',
  name: 'Breadcrumbs Common',
  siteIdentifier: 'petsafe',

  homeLabel: 'Home',
  accountLabel: 'Account',
  addressesLabel: 'Addresses',
  signInLabel: 'Sign in',
  createAccountLabel: 'Create Account',
  settingsLabel: 'Settings',
  accountVerificationLabel: 'Account Verification',
  searchUnavailableLabel: 'Search unavailable',
  searchLabel: 'Search',
  searchStateLabel: 'Search: {hits} results for {searchState}',
  reactivateLabel: 'Reactivate',
  resetPasswordLabel: 'Reset Password',
  subscriptionsLabel: 'Subscriptions',
  paymentMethodsLabel: 'Payment Methods',
};

exports.breadcrumbsCommon = breadcrumbsCommon;
