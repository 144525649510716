const searchPage = {
  key: 'searchPage',
  name: 'Search Page',
  siteIdentifier: 'petsafe',

  title: 'Search - PetSafe',
  titleWithQuery: `Search: {query} - PetSafe`,
  currentlyUnavailableMessage: 'Search is currently unavailable',
  countOfProductsTitle: 'Products ({count})',
  countOfArticlesTitle: 'Articles ({count})',
};

exports.searchPage = searchPage;
