import { useStaticQuery, graphql } from 'gatsby';

const useSiteSettings = locale => {
  let siteSettingsCache = {};
  try {
    // This file is generated during runtime, so it is not committed to Git
    // This is expected for the IDE to show an error message before the first build
    // eslint-disable-next-line import/no-unresolved, global-require
    siteSettingsCache = require('../../content/additionalContext.json');
  } catch {
    siteSettingsCache = {};
  }

  const { allSanitySiteSettings } = useStaticQuery(
    graphql`
      query SanitySiteSettingsHookPetSafeQuery {
        allSanitySiteSettings(
          filter: {
            _type: { eq: "siteSettings" }
            siteIdentifier: { eq: "petsafe" }
          }
        ) {
          nodes {
            language
            phone
            contactEmail
            deliveryTimeEstimate
            shipping
            returnDays
            contactPage {
              slug {
                current
              }
            }
            footerCopyright {
              _key
              copyrightName
              copyrightUrl
            }
            newsletter {
              newsletterDescription
              newsletterTitle
            }
            socialMedia {
              facebookBool
              facebookUrl
              instagramBool
              instagramUrl
              youtubeBool
              youtubeUrl
              twitterBool
              twitterUrl
              pinterestBool
              pinterestUrl
              tiktokBool
              tiktokUrl
            }
            ordersPageHeadline
            ordersPageSubHeadline
            subscriptionsPageHeadline
            subscriptionsPageSubHeadline
            videosPageHeadline
            videosPageSubHeadline
            addressesPageHeadline
            addressesPageSubHeadline
            settingsPageHeadline
            settingsPageSubHeadline
          }
        }
      }
    `
  );

  const defaultSettings = {
    contactPage: '/contact-us/',
    phone: '1-866-738-4379',
    ordersPageHeadline: 'Your Orders',
    ordersPageSubHeadline: 'View your order history and manage your account.',
    subscriptionsPageHeadline: 'Subscriptions',
    subscriptionsPageSubHeadline:
      'Manage your subscriptions, update payment methods, and view subscription details here.',
    videosPageHeadline: 'Training Videos',
    videosPageSubHeadline:
      'Exclusive training videos to help you train your pet with Invisible Fence technology.',
    addressesPageHeadline: 'Your Addresses',
    addressesPageSubHeadline: 'Manage your delivery addresses here.',
    settingsPageHeadline: 'Your Account',
    settingsPageSubHeadline: 'Manage your account details and password here.',
  };

  const fallbackSanitySiteSettings = allSanitySiteSettings.nodes[0];
  let sanitySiteSettings = locale
    ? allSanitySiteSettings.nodes.find(s => s.language === locale) ??
      fallbackSanitySiteSettings
    : fallbackSanitySiteSettings;

  if (siteSettingsCache?.[locale]) {
    sanitySiteSettings = {
      ...sanitySiteSettings,
      ...siteSettingsCache[locale],
    };
  }

  const settings = { ...defaultSettings, ...sanitySiteSettings };

  // Ensure contactPage slug starts with '/'
  if (sanitySiteSettings?.contactPage?.slug?.current) {
    settings.contactPage =
      sanitySiteSettings.contactPage.slug.current.startsWith('/')
        ? sanitySiteSettings.contactPage.slug.current
        : `/${sanitySiteSettings.contactPage.slug.current}`;
  }

  return settings;
};

export default useSiteSettings;
